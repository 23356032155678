<template>
    <template v-if="moduleLoaded">
        <div :id="uid" ref="froalaEditorRef" class="h-100 o365-froala"></div>
    </template>
</template>

<script setup lang="ts">
import type { DataObject } from 'o365-dataobject';
import { importUtils, logger } from 'o365-utils';
import { ref, nextTick, onBeforeUnmount, watch } from 'vue';

export interface ICustomElement {
    type: "command" | "quickInsert",
    name: string,
    options: {
        [key: string]: any
    }
}

export interface IProps {
    dataObject?: DataObject,
    simple?: boolean,
    editorOptions?: any,
    customElements?: ICustomElement[],
    toolbarButtons?: Record<string, { buttons: string[], buttonsVisible?: number, align?: string }>
};

export interface IEmits {
    (e: 'save'): void,
};

const props = defineProps<IProps>();

const emit = defineEmits<IEmits>();

const model = defineModel<string>();

let internalEditorValue: null | string = null; // Stop deps traverse loops
watch(() => model.value, (pNewValue) => {
    if (froalaEditor && internalEditorValue != pNewValue) {
        froalaEditor?.html?.set(pNewValue);
        internalEditorValue = pNewValue;
    }
});


const uid = `froala-editor-${crypto.randomUUID()}`
const cleanupTokens: (() => void)[] = [];
const moduleLoaded = ref(false);
loadFroala().then(() => {
    moduleLoaded.value = true;
    return nextTick();
}).then(() => {
    initCustomElements();
}).then(() => {
    initEditor();
}).catch(ex => {
    logger.error(ex);
});


let froalaEditor = null;
const froalaEditorRef = ref<HTMLElement>();

function initCustomElements() {
    if (!props.customElements) {
        return;
    }

    for (let elem of props.customElements) {
        switch (elem.type) {
            case "command":
                FroalaEditor.RegisterCommand(elem.name, elem.options);
                break;

            case "quickInsert":
                FroalaEditor.RegisterQuickInsertButton(elem.name, elem.options);
                break;
        }
    }
}

function getDefaultOptions() {
    return {
        height: '100%',
        attribution: false,
        useClasses: true,
        pastePlain: true,
        wordPasteKeepFormatting: false,
        imagePaste: false, // Because the event returns img as base64, custom event listener created instead
        imageStyles: {
            "rounded": "Rounded",
            "border": "Border",
        },
        charCounterCount: false,
        theme: "dark",
        toolbarInline: false,
        videoUpload: false,
        imageMove: true,
        saveInterval: 2500,
        fontSizeSelection: true,
        quickInsertButtons: ["quickInsertImage", "video", "embedly", "table", "ul", "ol", "hr"],
        imageEditButtons: ["imageAlign", "imageCaption", "imageRemove", "|", "imageLink", "linkOpen", "linkEdit", "linkRemove", "-", "imageDisplay", "imageStyle", "imageAlt", "imageSize"],
        fontSize: ["18", "20", "22", "24", "26", "28", "30", "32", "34", "36", "40", "48", "60", "72", "96"],
        htmlRemoveTags: ["script"],
        pasteDeniedAttrs: ["id"],
        shortcutsEnabled: ["show", "bold", "italic", "underline", "indent", "outdent", "undo", "redo", "createLink"], // removed strikeThrough shortcut, because it uses Ctrl + S
        paragraphFormat: {
            N: "Normal",
            H1: "Heading 1",
            H2: "Heading 2",
            H3: "Heading 3",
            H4: "Heading 4",
            PRE: "Code"
        },
        paragraphFormatSelection: true,
        paragraphStyles: {
            "fr-text-gray": "Gray",
            "fr-text-bordered": "Bordered",
            "fr-text-spaced": "Spaced",
            "fr-text-uppercase": "Uppercase"
        },
        toolbarButtons: {
            moreText: {
                buttons: ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript", "fontFamily", "fontSize", "textColor", "backgroundColor", "inlineClass", "inlineStyle", "clearFormatting"]
            },
            
            moreParagraph: {
                buttons: ["paragraphFormat", "customLeadStyle", "alignLeft", "alignCenter", "formatOLSimple", "alignRight", "alignJustify", "formatOL", "formatUL", "paragraphStyle", "lineHeight", "outdent", "indent", "quote"],
                buttonsVisible: 2
            },
            moreRich: {
                buttons: ["trackChanges", "insertLink", "customImages", "insertTable", "emoticons", "fontAwesome", "specialCharacters", "embedly", "insertHR"],
                buttonsVisible: 4
            },
            moreMisc: {
                buttons: ["undo", "redo", "customSave", "fullscreen", "print", "spellChecker", "selectAll", "html", "help"],
                align: "right",
                buttonsVisible: 3
            },
            trackChanges: {
                buttons: ["showChanges", "applyAll", "removeAll", "applyLast", "removeLast"],
                buttonsVisible: 0
            }
        },
    };
}
function getSimpleOptions() {
    initSimpleMode();
    return {
        height: '100%',
        attribution: false,
        useClasses: true,
        pastePlain: true,
        wordPasteKeepFormatting: false,
        imagePaste: false, // Because the event returns img as base64, custom event listener created instead
        imageStyles: {
            "rounded": "Rounded",
            "border": "Border",
        },
        charCounterCount: false,
        theme: "dark",
        toolbarInline: false,
        videoUpload: false,
        imageMove: true,
        saveInterval: 1000,
        fontSizeSelection: true,
        quickInsertEnabled: false,
        imageEditButtons: ["imageAlign", "imageCaption", "imageRemove", "|", "imageLink", "linkOpen", "linkEdit", "linkRemove", "-", "imageDisplay", "imageStyle", "imageAlt", "imageSize"],
        htmlRemoveTags: ["script"],
        pasteDeniedAttrs: ["id"],
        shortcutsEnabled: ["show", "bold", "italic", "underline", "indent", "outdent", "undo", "redo", "createLink"], // removed strikeThrough shortcut, because it uses Ctrl + S
        paragraphFormat: {
            N: "Normal",
            H3: "Header",
            H4: "Sub",
        },
        paragraphFormatSelection: true,
        toolbarButtons: {
            moreText: {
                buttons: ["bold", "italic", "underline"]
            },
            moreParagraph: {
                buttons: ['paragraphFormat', "formatOLSimple", "formatUL"],
                buttonsVisible: 4
            },
            moreRich: {
                buttons: ["insertLink", "customImages",],
                buttonsVisible: 2
            },
            moreMisc: {
                buttons: ["undo", "redo", "fullscreen", "html" ],
                align: "right",
                buttonsVisible: 4
            },
        },
    };
}


function initEditor() {
    let options: Record<string, any> = props.simple ? getSimpleOptions() : getDefaultOptions();

    if (props.editorOptions) {
        options = {
            ...options,
            ...props.editorOptions
        };
    }

    if (props.toolbarButtons) {
        options.toolbarButtons = props.toolbarButtons;
    }

    options.key = '1CC3kD9B5E4F6G4F3bHIMFF1EWBXIJb1BZLZFh1i1MXQLjE4C3F3I3B4D6C6E3C3F2==';

    if (options.events == null) { options.events = {}; }
    options.events['contentChanged'] = () => {
        if (froalaEditor) {
            internalEditorValue = froalaEditor.html.get()
            model.value = internalEditorValue;
        }
    }
    options.events['save.before'] = () => {
        emit('save');
    }


    froalaEditor = new FroalaEditor(froalaEditorRef.value, options, (a, b, c) => {
        froalaEditor.html.set(model.value ?? '');
        internalEditorValue = model.value ?? '';
    });

}

onBeforeUnmount(() => {
    cleanupTokens.forEach(ct => ct);
});

</script>

<script lang="ts">
let FROALA_INIT_PROMISE: Promise<void> | null = null;

async function loadFroala() {
    if (FROALA_INIT_PROMISE == null) {
        let resolve = () => { };
        let reject = (pEx: Error) => { };
        FROALA_INIT_PROMISE = new Promise<void>((res, rej) => {
            resolve = res;
            reject = rej;
        });

        importUtils.loadScript('froala-editor/js/froala_editor.pkgd.min.js').then(() => {
            return importUtils.loadScript('froala-editor/js/plugins/word_paste.min.js');
        }).then(() => {
            return importUtils.loadStyle('froala-editor/css/froala_editor.pkgd.min.css');
        }).then(() => {

            resolve();
        }).catch((ex) => {
            reject(ex);
        });

        return FROALA_INIT_PROMISE;
    } else {
        return FROALA_INIT_PROMISE;
    }
}

let simpleButtonsRegistered = false;
function initSimpleMode() {
    if (simpleButtonsRegistered || FroalaEditor == null) { return;}
    simpleButtonsRegistered = true;
    FroalaEditor.DefineIconTemplate('bootstrap', '<i class="bi [NAME]" style="font-size:large;"></i>');

    // FroalaEditor.DefineIcon('headingN', { NAME: 'Normal', template: 'text' });
    FroalaEditor.RegisterCommand('Normal', {
        title: 'Normal',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.paragraphFormat.apply('P');
        }
    });

    FroalaEditor.DefineIcon('headingH4', { NAME: 'bi-type-h4', template: 'bootstrap' });
    FroalaEditor.RegisterCommand('headingH4', {
        title: 'Main Heading',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.paragraphFormat.apply('H4');
        }
    });

    FroalaEditor.DefineIcon('headingH5', { NAME: 'bi-type-h5', template: 'bootstrap' });
    FroalaEditor.RegisterCommand('headingH5', {
        title: 'Sub Heading',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.paragraphFormat.apply('H5');
        }
    });

    FroalaEditor.DefineIcon('unorderedList', { NAME: 'list-ul', SVG_KEY: 'unorderedList' });
    FroalaEditor.RegisterCommand('unorderedList', {
        title: 'Unordered List',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.commands.exec('formatUL');
        }
    });
}


</script>

<style>
.o365-froala .fr-wrapper h1 {
    font-size: 1.7rem;
    font-weight: 600;
    margin-top: .5rem;
    margin-bottom: 0px;
}
.o365-froala .fr-wrapper h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: .5rem;
    margin-bottom: 0px;
}
.o365-froala .fr-wrapper h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: .5rem;
    margin-bottom: 0px;
}
.o365-froala .fr-wrapper h4 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: .5rem;
    margin-bottom: 0px;
}
</style>